import { lazy } from "react";

const PagesRoutes = [
  // Authentication
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../../view/pages/auth/forgot-password")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/resend-forgot-password",
    component: lazy(() =>
      import("../../view/pages/auth/resend-email/forgot-password")
    ),
    layout: "FullLayout",
    auth: false,
  },

  // Main Pages
  {
    path: "/dashboard",
    exact: true,
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/tarik-data",
    component: lazy(() => import("../../view/pages/tarik-data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/pending",
    component: lazy(() => import("../../view/pages/call/status/pending")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/correct",
    component: lazy(() => import("../../view/pages/call/status/correct")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/indikasi",
    component: lazy(() => import("../../view/pages/call/status/indikasi")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/reject",
    component: lazy(() => import("../../view/pages/call/status/reject")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/false",
    component: lazy(() => import("../../view/pages/call/status/false")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/validate",
    component: lazy(() => import("../../view/pages/call/validate")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/detail",
    component: lazy(() => import("../../view/pages/call/detail")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/call/detail-panggilan",
    component: lazy(() => import("../../view/pages/call/detail-panggilan")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/reschedule/data",
    component: lazy(() => import("../../view/pages/reschedule/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/indikasi/data",
    component: lazy(() => import("../../view/pages/indikasi/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/reschedule/data",
    component: lazy(() => import("../../view/pages/reschedule/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/history/data",
    component: lazy(() => import("../../view/pages/history/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/eksepsi/data",
    component: lazy(() => import("../../view/pages/eksepsi/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/eksepsi/form",
    component: lazy(() => import("../../view/pages/eksepsi/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/eksepsi/detail",
    component: lazy(() => import("../../view/pages/eksepsi/detail")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Other
  {
    path: "/user/data",
    component: lazy(() => import("../../view/pages/user/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/user/form",
    component: lazy(() => import("../../view/pages/user/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/role/data",
    component: lazy(() => import("../../view/pages/role/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/role/form",
    component: lazy(() => import("../../view/pages/role/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Regional
  {
    path: "/regional/data",
    component: lazy(() => import("../../view/pages/regional/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/regional/form",
    component: lazy(() => import("../../view/pages/regional/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Area
  {
    path: "/area/data",
    component: lazy(() => import("../../view/pages/area/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/area/form",
    component: lazy(() => import("../../view/pages/area/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Cabang
  {
    path: "/cabang/data",
    component: lazy(() => import("../../view/pages/cabang/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/cabang/form",
    component: lazy(() => import("../../view/pages/cabang/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Profile
  {
    path: "/profile/my-profile",
    component: lazy(() => import("../../view/pages/profile/my-profile")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  {
    path: "/profile/change-password",
    component: lazy(() => import("../../view/pages/profile/change-password")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
];

export default PagesRoutes;
