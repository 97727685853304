export const api_base_url = process.env.REACT_APP_BASE_URL + "/api/v1";

export const API_URL = {
  // Authentication
  login: api_base_url + "/auth/login",
  forgotPassword: api_base_url + "/auth/request-password-reset",

  dashboard: api_base_url + "/dashboard",
  pullData: api_base_url + "/data",
  call: api_base_url + "/panggilan",
  eksepsi: api_base_url + "/eksepsi",
  // User
  user: api_base_url + "/users",
  staff: api_base_url + "/staffs",
  area: api_base_url + "/areas",
  cabang: api_base_url + "/cabangs",
  regional: api_base_url + "/regionals",
  role: api_base_url + "/roles",
  permission: api_base_url + "/permissions",
};
